import './Schema.scss';
import { useEffect, useState } from 'react';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';

export let Schema = ({ boilersHouses }) => {
  useEffect(() => {
    // Ваши эффекты, если они необходимы
  }, []);

  let [checkedBoiler, setCheckedBoiler] = useState(boilersHouses[0]?.pk);

  let changeDynamicBoiler = (e) => {
    setCheckedBoiler(parseInt(e.target.value));
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <label className="form-label font-monospace">Выберите котельную</label>
          <select className="form-select tcontrol__select" onChange={e => changeDynamicBoiler(e)}>
            {boilersHouses.map(item => (
              <option key={item.pk} value={item.pk} selected={item.pk === checkedBoiler}>{item.address}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="row schema__row">
        <div className="col">
          {boilersHouses.length !== 0 ? (
            <p className="fs-6">
              Снизу представлены схемы и другие медиафайлы, относящиеся к котельной{' '}
              <span className='font-monospace'>
                {boilersHouses.find(item => item.pk === checkedBoiler)?.address}
              </span>
              . <br /> Чтобы подробно рассмотреть файл, <span className="fst-italic">нажмите на его иконку.</span>
            </p>
          ) : (
            <LoadingSpinner />
          )}
        </div>
      </div>

      <div className="row schema__row">
        <div className="col">
          {boilersHouses.length !== 0 && (
            <div className="schema__img-items">
              <a
                className="schema__img-item"
                href={boilersHouses.find(item => item.pk === checkedBoiler)?.newtwork_schema}
                target="_blank"
              >
                <img
                  src={boilersHouses.find(item => item.pk === checkedBoiler)?.newtwork_schema}
                  alt=""
                />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
