import {Route, Routes} from 'react-router-dom';
import './scss/style.scss'

import ProtectedRoute from './utils/ProtectedRoute';
import {AuthProvider} from './context/AuthContext';


import Main from "./pages/Main";
import FarePresent from "./pages/FarePresent";
import Dashboard from './pages/Dashboard';
import VzyPage from "./pages/VzyPage";
import LoginPage from './pages/LoginPage';
import NewsPage from './pages/News';
import RulesPage from './pages/Rules';
import FareDemo from './services/FareDemo';
import AdsPage from './pages/AdsPage';
import TControlPage from './pages/TControlPage';

import Fare from './services/Fare';
import Wear from "./services/Wear";
import Ads from './services/Ads';
import TAds from './services/TAds';
import MapP from './services/Map';
import DynamicValuesRSO from "./services/DynamicValuesRso";
import {Hydrologist} from './services/Hydrologist/Hydrologist';
import {RSODynamic} from './services/RSODynamic/RSODynamic';
import TControl from "./services/TControl/TControl";
import DetailedInfoPage from './pages/DetailInfo';
import DetailedInfoPageTeplo from './pages/DetailInfoTeplo';
import WearPage from './pages/WearPage';
// import DispatcherCheck from "./services/DispatcherCheck";
import React from "react";
import {AppWear} from './services/HouseWear/App'


function App() {
    return (
        <>
            <div className='App'>
                <AuthProvider>
                    <Routes>
                        <Route path='/' element={<Main/>}/>
                        <Route path='/login' element={<LoginPage/>}/>
                        <Route path='/news' element={<NewsPage/>}/>
                        <Route path='/rules' element={<RulesPage/>}/>
                        <Route path='/present/fare' element={<FarePresent/>}/>
                        <Route path='/present/rso/dynamic' element={<VzyPage/>}/>
                        <Route path='/services/fare/demo' element={<FareDemo/>}/>
                        <Route path='/present/ads' element={<AdsPage/>}/>
                        <Route path='/present/wear' element={<WearPage/>}/>
                        <Route path='/present/tcontrol' element={<TControlPage/>}/>
                        <Route
                            path='/dashboard'
                            element={<ProtectedRoute>{<Dashboard/>}</ProtectedRoute>}
                        />
                        <Route
                            path='/services/fare'
                            element={<ProtectedRoute>{<Fare/>}</ProtectedRoute>}
                        />
                        {/* <Route
                            path='/services/rso/dynamic-old'
                            element={<ProtectedRoute>{<DynamicValuesRSO/>}</ProtectedRoute>}
                        />*/}
                        <Route
                            path='/services/rso/dynamic'
                            element={<ProtectedRoute>{<RSODynamic/>}</ProtectedRoute>}
                        />
                        <Route
                            path='/services/wear'
                            element={<ProtectedRoute>{<AppWear/>}</ProtectedRoute>}
                        />
                        <Route
                            path='/services/ads'
                            element={<ProtectedRoute><Ads/></ProtectedRoute>}
                        />
                        <Route
                            path='/services/Tads'
                            element={<ProtectedRoute><TAds/></ProtectedRoute>}
                        />
                        <Route path='/services/ads/detailed-info' element={<DetailedInfoPage/>}/>
                        <Route path='/services/Tads/detailed-info' element={<DetailedInfoPageTeplo/>}/>
                        <Route
                            path='/map'
                            element={<ProtectedRoute>{<MapP/>}</ProtectedRoute>}
                        />
                        <Route
                            path='/services/tcontrol'
                            element={<ProtectedRoute>{<TControl/>}</ProtectedRoute>}
                        />
                        <Route
                            path='/services/rso/hydrologist'
                            element={<ProtectedRoute>{<Hydrologist/>}</ProtectedRoute>}
                        />
                        {/* <Route
                            path='/services/dispatchercheck'
                            element={<ProtectedRoute>{<DispatcherCheck/>}</ProtectedRoute>}
                        /> */}
                    </Routes>
                </AuthProvider>
            </div>
        </>
    );
}

export default App;
