import Footer from "../components/Footer";
import Header from "../components/Header";
import React, { useState } from "react";
import s1 from '../images/s1.png';
import s2 from '../images/s2.png';
import s3 from '../images/s3.png';
import s4 from '../images/s4.png';
import s5 from '../images/s5.png';
import s6 from '../images/s6.PNG';





const RulesPage = () => {


    return (
        <>
            <Header />
            <h1 className="RulesPage__Title">Авторские права</h1>
            <div className="RulesPage__PhotoGallery">
                <div className="RulesPage__PhotoGalleryItem"><img src={s1} alt="Photo 1" /></div>
                <div className="RulesPage__PhotoGalleryItem"><img src={s2} alt="Photo 2" /></div>
                <div className="RulesPage__PhotoGalleryItem"><img src={s3} alt="Photo 3" /></div>
                <div className="RulesPage__PhotoGalleryItem"><img src={s4} alt="Photo 4" /></div>
                <div className="RulesPage__PhotoGalleryItem"><img src={s5} alt="Photo 5" /></div>
            </div>
            <div className="RulesPage__PhotoCentered">
                <div className="RulesPage__PhotoGalleryItem RulesPage__CenteredItem"><img src={s6} alt="Photo 6" /></div>
            </div>
            <Footer />
        </>
    )
}

export default RulesPage;