import React from "react";
import "../../css/Wear.css";
import { API_URL } from "../../../../config";
import { useContext } from "react";
import AuthContext from "../../../../context/AuthContext";

const ButtonElementWear = ({ onButtonClick }) => {
  let { authTokens } = useContext(AuthContext);
  const id = localStorage.getItem("id");
  // const accessToken = localStorage.getItem("accessToken");

  const fetchData = async (elementType) => {
    const api = `${API_URL}/Wear/House/houseElementWear/${id}/${elementType}/`;

    try {
      const response = await fetch(api, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      });

      if (!response.ok) {
        throw new Error("Ошибка при получении данных");
      }

      const data = await response.json();
      return data.rez;
    } catch (error) {
      console.error("Ошибка !", error.message);
      return null;
    }
  };

  const handleAddClick = async () => {
    const results = {
      foundation: await fetchData(0),
      wall: await fetchData(1),
      floor: await fetchData(2),
      balcony: await fetchData(3),
      roof: await fetchData(4),
      roofing: await fetchData(5),
      sewerage: await fetchData(6),
      chute: await fetchData(7),
      сentralHeating: await fetchData(8),
      coldWaterSupply: await fetchData(9),
      hotWaterSupply: await fetchData(10),
      wiring: await fetchData(11),
    };

    let tableData;

    // Функция для преобразования числа в проценты с округлением до двух знаков после запятой
    function toPercent(value) {
      return value.toFixed(2) + "%";
    }

    // Преобразование всех значений в проценты
    tableData = [
      ["Фундамент", "Стены", "Балконы и т.д", "Крыша", "Кровля", "Перекрытия"],
      [
        toPercent(results.foundation),
        toPercent(results.wall),
        toPercent(results.balcony),
        toPercent(results.roof),
        toPercent(results.roofing),
        toPercent(results.floor),
      ],
      [
        "Канализация",
        "Мусоропровод",
        "Центральное отопление",
        "Холодное водоснабжение",
        "Горячее водоснабжение",
        "Электрооборудование",
      ],
      [
        toPercent(results.sewerage),
        toPercent(results.chute),
        toPercent(results.сentralHeating),
        toPercent(results.coldWaterSupply),
        toPercent(results.hotWaterSupply),
        toPercent(results.wiring),
      ],
    ];

    console.log(results);

    onButtonClick("Износ по элементам", tableData);
  };

  return (
    <button className="btn_addwear_wear" onClick={handleAddClick}>
      {" "}
      Износ элементов{" "}
    </button>
  );
};

export default ButtonElementWear;
