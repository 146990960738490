import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import AuthContext from '../../context/AuthContext';
import { API_URL } from '../../config';
import Jelly from "../../components/Jelly";
import Layout from '../../components/Layout';
import settingSvg from '../../images/settings_btn.svg'
import Timer from '../../components/Timer'
import { Schema } from "./Schema/Schema";

const TControl = () => {

  let navigate = useNavigate();
  useEffect(() => {
    getProfile();
    fetchActiveSmena();
    getData('boilers', '/TControl/boilerhouse/', setBoilersHouses);
    if (localStorage.getItem('smena_id')) getData('dynamicValues', `/TControl/dynamic_settings/smena/${localStorage.getItem('smena_id')}/`, setDynamicValuesGet);
    getData('', '/TControl/boiler/', setBoilersHouseBoiler);
    getData('', '/TControl/boilerhousepump/', setBoilersHousePump);
  }, []);

  const { handleSubmit, reset } = useForm({ mode: 'onBlur' });

  let { authTokens, logoutUser } = useContext(AuthContext);
  let [profile, setProfile] = useState('');
  let [jobTitle, setJobTitle] = useState('');

  // let [smens, setSmens] = useState([]);
  let [circle, setCircle] = useState(false);
  let [enterFullName, setEnterFullName] = useState('');//Полное имя при создании смены
  let [activeSmena, setActiveSmena] = useState([]);
  let [boilersHouses, setBoilersHouses] = useState([]);
  let [boilersHouseBoiler, setBoilersHouseBoiler] = useState([]);
  let [boilersHousePump, setBoilersHousePump] = useState([]);
  let [dynamicValuesPost, setDynamicValuesPost] = useState([]);
  let [dynamicValuesGet, setDynamicValuesGet] = useState([]);
  let [timeLastInput, setTimeLastInput] = useState(0);
  let [dynamicValuesCheckedBoiler, setDynamicValuesCheckedBoiler] = useState(-1);//Выбранный котел в select 
  let [coolantActive, setCoolantActive] = useState(false);//Включение и отключение теплоносителя
  let [addButtons, setAddButtons] = useState({ dynamic: 1, equipment: 1 })

  let getProfile = async () => {
    let response = await fetch(API_URL + '/user/profile/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setProfile(data[0]);
      setJobTitle(data[0].job_title);
      if (!data[0].RSO_access) {
        navigate('/', { replace: true });
      }

    } else if (data["detail"] === "Учетные данные не были предоставлены.") {
      logoutUser();
    } else {
      localStorage.setItem('last_page', '/services/rso/dynamic');
      navigate('/', { replace: true });
    }
  }

  //Забираем активную смену по ключу из localstorage
  const fetchActiveSmena = async () => {
    let smena_id = localStorage.getItem("smena_id");
    if (smena_id) {
      let response = await fetch(API_URL + '/TControl/smena/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      });

      let data = await response.json();
      if (response.status === 200) {
        if (data.length === 0) {//Удаление id смены из localStorage если данных нет 
          localStorage.removeItem("smena_id")
        } else {//Если какие-то данные пришли
          setActiveSmena(data[0]);//Записываем только первую из полученных смен. На случай создания двух.
          localStorage.setItem("smena_id", data[0].pk)//На тот же случай перезаписываем и id в localStorage
        }
      }
    }
  }

  const [activeIndex, setActiveIndex] = useState(1);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  //------------------------------------------------------
  //--ЛЕГЕНДЫ
  //------------------------------------------------------

  let getData = async (what, link, setter, getter) => {
    let response = await fetch(API_URL + link, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });

    let data = await response.json();
    if (response.status === 200) {
      setter(data);
    }

    switch (what) {
      case 'boilers':
        data.map(item => {
          setCoolantSettings(coolantSettings => ({ ...coolantSettings, [item.pk]: item.сoolant_activity }))
        })

      default:
        break;
    }
  }

  let patchData = async (what, link, data, e) => {
    if (e !== undefined) { e.preventDefault() }
    let response = await fetch(API_URL + link, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
      body: JSON.stringify({
        ...data
      }),
    });

    if (response.status === 200) {
      switch (what) {
        case "сoolant_activity":
          window.location.reload();
          changeModalStatus()
          break;
        case "smena":
          setActiveSmena({});
          localStorage.removeItem('smena_id')
          window.location.reload();
          setCircle(false)
          break;

        default:
          break;
      }
    } else { }
  }

  let postData = async (what, link, data, setter, getter, e) => {
    if (e !== undefined) { e.preventDefault() }
    let response = await fetch(API_URL + link, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
      body: JSON.stringify({
        ...data
      }),
    });

    let data1 = await response.json();

    if (response.status === 201 && setter !== undefined) {
      setter(data1)
    } else { }
    switch (what) {
      case 'smena':
        localStorage.setItem("smena_id", data1.pk)
        setCircle(false)
        break;
      case 'dynamicValues':
        setDynamicValuesPost([]);
        getData('dynamicValues', `/TControl/dynamic_settings/smena/${localStorage.getItem('smena_id')}/`, setDynamicValuesGet)
        setCircle(false)
        const inputElements = document.querySelectorAll('.tcontrol__input');
        inputElements.forEach(input => {
          input.value = ''; // Очищаем значение input
        });
        break;

      default:
        break;
    }
  }

  const fillForm = (e, getter, setter, pk) => {
    let name = e.target.name;
    let value = e.target.value;
    if (e.target.type === "number") value = parseFloat(value)
    setter({ ...getter, [name]: value })
  }

  function formatDate(dateTimeString) {//Преобразует дату и время в нормальную форму
    const date = new Date(dateTimeString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    return `${hours}:${minutes} ${day}.${month}.${year}`;
  }

  let changeDynamicBoiler = (e) => {
    setDynamicValuesCheckedBoiler(parseInt(e.target.value))
    setDynamicValuesPost({})
    boilersHouses.map(item => {
      if (item.pk === parseInt(e.target.value)) {
        if (item.сoolant_activity) {
          setCoolantActive(true)
        } else {
          setCoolantActive(false)
        }
      }
    })
  }

  //------------------------------------------------------
  //--ОТРИСОВКА ВСЯКОГО 
  //------------------------------------------------------

  let printDynamicInput = () => {//Заполнение инпутов в динамике
    return (
      <div>
        <div>
          <div className="row">
            <div className="col">
              <label className="form-label">Выберите котельную</label>
              <select className="form-select tcontrol__select" onChange={e => changeDynamicBoiler(e)}>
                <option value="-1" disabled selected={dynamicValuesCheckedBoiler === -1}>---</option>
                {boilersHouses.map(item => {
                  return (
                    <option value={item.pk} selected={item.pk === dynamicValuesCheckedBoiler}>{item.address}</option>
                  )
                })}
              </select>
            </div>
          </div>
          {dynamicValuesCheckedBoiler !== -1 ? (
            <>
              <h5 className="tcontrol__dynamicValues-title">Теплоноситель</h5>
              <div className="row row-cols-4">
                <div className="col">
                  <label className="form-label">Температура подачи в сеть</label>
                  <input className="form-control tcontrol__input" type="number" min="0" step="0.01" name="t1" onChange={(e) => fillForm(e, dynamicValuesPost, setDynamicValuesPost)} disabled={!coolantActive} value={dynamicValuesPost.t1} placeholder="T1" />
                </div>
                <div className="col">
                  <label className="form-label">Давление подачи теплоносителя</label>
                  <input className="form-control tcontrol__input" type="number" min="0" step="0.01" name="p1" onChange={(e) => fillForm(e, dynamicValuesPost, setDynamicValuesPost)} disabled={!coolantActive} placeholder="P1" />
                </div>
                <div className="col">
                  <label className="form-label">Обратка температура</label>
                  <input className="form-control tcontrol__input" type="number" min="0" step="0.01" name="t2" onChange={(e) => fillForm(e, dynamicValuesPost, setDynamicValuesPost)} disabled={!coolantActive} placeholder="T2" />
                </div>
                <div className="col">
                  <label className="form-label">Обратка давление</label>
                  <input className="form-control tcontrol__input" type="number" min="0" step="0.01" name="p2" onChange={(e) => fillForm(e, dynamicValuesPost, setDynamicValuesPost)} disabled={!coolantActive} placeholder="P2" />
                </div>
              </div>
              <h5 className="tcontrol__dynamicValues-title">ГВС</h5>
              <div className="row row-cols-4">
                <div className="col">
                  <label className="form-label">Температура подачи в сеть</label>
                  <input className="form-control tcontrol__input" type="number" min="0" step="0.01" name="t3" onChange={(e) => fillForm(e, dynamicValuesPost, setDynamicValuesPost)} placeholder="T3" required />
                </div>
                <div className="col">
                  <label className="form-label">Давление подачи теплоносителя</label>
                  <input className="form-control tcontrol__input" type="number" min="0" step="0.01" name="p3" onChange={(e) => fillForm(e, dynamicValuesPost, setDynamicValuesPost)} placeholder="P3" required />
                </div>
                <div className="col">
                  <label className="form-label">Обратка температура</label>
                  <input className="form-control tcontrol__input" type="number" min="0" step="0.01" name="t4" onChange={(e) => fillForm(e, dynamicValuesPost, setDynamicValuesPost)} placeholder="T4" required />
                </div>
                <div className="col">
                  <label className="form-label">Обратка давление</label>
                  <input className="form-control tcontrol__input" type="number" min="0" step="0.01" name="p4" onChange={(e) => fillForm(e, dynamicValuesPost, setDynamicValuesPost)} placeholder="P4" required />
                </div>
              </div>
              <h5 className="tcontrol__dynamicValues-title">Остальное</h5>
              <div className="row row-cols-4">
                <div className="col">
                  <label className="form-label">Давление воды на вводе</label>
                  <input className="form-control tcontrol__input" type="number" min="0" step="0.01" name="pressure" onChange={(e) => fillForm(e, dynamicValuesPost, setDynamicValuesPost)} placeholder="Pвх" required />
                </div>
                <div className="col">
                  <label className="form-label">Давление газа на вводе</label>
                  <input className="form-control tcontrol__input" type="number" min="0" step="0.01" name="gas" onChange={(e) => fillForm(e, dynamicValuesPost, setDynamicValuesPost)} placeholder="Газ" required />
                </div>
                <div className="col">
                  <label className="form-label">Примечание</label>
                  <input className="form-control tcontrol__input" type="text" name="note" onChange={(e) => fillForm(e, dynamicValuesPost, setDynamicValuesPost)} />
                </div>
              </div>
            </>
          ) : ("")}

        </div>
      </div>
    )
  }

  let printOperationTable = () => {//Вывод большой сводной таблицы в динамике

    return (
      <table data-bs-spy="scroll" className="table table-striped table-bordered  tcontrol__table">
        <thead>
          <tr>
            <th rowSpan='2'>Час</th>
            {boilersHouses.map(item => (
              <th colSpan={item.сoolant_activity ? '10' : '6'}>{item.address}</th>
            ))}
          </tr>
          <tr>
            {boilersHouses.map(item => {
              return (
                <>
                  <th>Pвх</th>
                  {item.сoolant_activity ? (
                    <>
                      <th>T1</th>
                      <th>P1</th>
                      <th>T2</th>
                      <th>P2</th>
                    </>
                  ) : ("")}
                  <th>T3</th>
                  <th>P3</th>
                  <th>T4</th>
                  <th>P4</th>
                  <th>Pгаз</th>
                </>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {dynamicValuesGet.map(item => {
            return (
              <tr>
                <td>{formatDate(item.created_at).split(' ')[0]}</td>
              </tr>
            )
          })}

        </tbody>
      </table>
    );

  }

  let [modal, setModal] = useState(false);//Состояние модального окна
  const changeModalStatus = () => {
    setModal(!modal);
  };

  //------------------------------------------------------------------------------------
  //--Модальное окно--------------------------------------------------------------------
  //------------------------------------------------------------------------------------

  const handleModalDisplay = () => {
    if (document.querySelector('.modal')) {
      document.querySelector('.modal').style.display = modal ? 'block' : 'none';
    }
  };

  let modalWindow = () => {
    return (
      <div className="modal" tabindex="-1" onClick={() => handleModalDisplay()}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Настройки ТеплоКонтроль</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => changeModalStatus()}></button>
            </div>
            <form className="modal-body__form" onSubmit={e => (handleSubmit(uploadSettings(e, 'сoolant_activity')))}>
              <div className="modal-body">
                <p><b>Выберите котельные с включенным теплоснабжением:</b></p>
                {boilersHouses.map(item => {
                  return (
                    <>
                      <div className="form-check">
                        <input className="form-check-input"
                          type="checkbox"
                          value={item.pk}
                          id={"settingsBoilerhouse-" + item.pk}
                          checked={coolantSettings[item.pk]}
                          onChange={() => changeCoolantActivity(item.pk, !coolantSettings[item.pk])}
                        />
                        <label className="form-check-label" for={"settingsBoilerhouse-" + item.pk}>
                          {item.address}
                        </label>
                      </div>
                    </>
                  )
                })}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => changeModalStatus()}>Закрыть</button>
                <button type="submit" className="btn btn-primary">Сохранить настройки</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }

  let [coolantSettings, setCoolantSettings] = useState()
  let changeCoolantActivity = (pk, flag) => {
    setCoolantSettings(coolantSettings => ({ ...coolantSettings, [pk]: flag }))
  }

  let uploadSettings = (e, settings) => {
    e.preventDefault()
    switch (settings) {
      case 'сoolant_activity':
        boilersHouses.map(item => {
          console.log(item);
          if (coolantSettings[item.pk] !== item.сoolant_activity) {//Если настройки обновились  значения не совпадают со старыми
            patchData('сoolant_activity', `/TControl/boilerhouse/${item.pk}/`, { сoolant_activity: coolantSettings[item.pk], }, undefined)
          }
        })
        break;

      default:
        break;
    }
  }

  return (
    <>
      <Layout title='ЭРА ЖКХ | ТЕПЛОконтроль' content='TControl' />
      {activeSmena.length === 0 ? (//Если активной смены нет, то создаем новую
        !circle ? (
          <>
            <div className="dynamicValuesRso__content">
              <div className="dynamicValuesRso__chooseSmensBox">
                <form className="dynamicValuesRso__changeWPS-form tcontrol__form tcontrol__register-form" onSubmit={() => (handleSubmit(postData("smena", '/TControl/smena/', { fullname: enterFullName }, setActiveSmena, undefined, undefined)
                ), setCircle(true))}>
                  <div className="tcontrol__form-row">
                    <div className="tcontrol__input-data tcontrol__input-data--register">
                      <input type="text" onChange={(e) => setEnterFullName(e.target.value)} required />
                      <div className="tcontrol__underline"></div>
                      <label>ФИО</label>
                    </div>
                  </div>
                  <button className="tcontrol__btn tcontrol__register-btn" type="submit">Создать смену</button>
                </form>
              </div>
            </div >
          </>
        ) : (
          <Jelly />
        )
      ) : (//Если активная смена все же есть, то сразу продолжаем работу 
        !circle ? (
          <>
            {modalWindow()}
            <div className="tcontrol">
              <div className="container">
                {/* Кнопка настроек внизу справа экрана */}
                <div className="tcontrol__settings">
                  <button className="tcontrol__settings-btn" onClick={changeModalStatus}>
                    <img className="tcontrol__settings-img" src={settingSvg} alt="" />
                  </button>
                </div>
                {handleModalDisplay()}
                <nav className="tcontrol__nav retro">
                  <div className={`tcontrol__nav-underline ${activeIndex === 0 ? "active0" : (activeIndex === 1 ? "active1" : (activeIndex === 2 ? "active2" : (activeIndex === 3 ? "active3" : "active4")))}`} style={{ transform: `translate3d(${(activeIndex) * 100}%,0,0)` }}></div>
                  <a className={`tcontrol__nav-link ${activeIndex === 0 ? 'active' : ''}`} onClick={() => handleClick(0)}>Котельные детально</a>
                  <a className={`tcontrol__nav-link ${activeIndex === 1 ? 'active' : ''}`} onClick={() => handleClick(1)} href="#dynamic">Динамика</a>
                  <a className={`tcontrol__nav-link ${activeIndex === 2 ? 'active' : ''}`} onClick={() => handleClick(2)}>Отчет на дату</a>
                  <a className={`tcontrol__nav-link ${activeIndex === 3 ? 'active' : ''}`} onClick={() => handleClick(3)} href="#closeSmena">Отказы оборудования</a>
                  <a className={`tcontrol__nav-link ${activeIndex === 4 ? 'active' : ''}`} onClick={() => handleClick(4)} href="#closeSmena">Сети/аварии</a>
                  <a className={`tcontrol__nav-link ${activeIndex === 5 ? 'active' : ''}`} onClick={() => handleClick(5)} href="#closeSmena">Закрытие смены</a>
                </nav>
              </div>

              {activeIndex === 2 || activeIndex === 3 || activeIndex === 4 ? (
                <div className="container">
                  <h2 className="text-warning">В данный момент в разработке...</h2>
                </div>
              ) : ("")}

              {activeIndex === 0 ? (//Если пользователь выбрал детально котельные
                  boilersHouses ? (
                    <Schema boilersHouses={boilersHouses} />
                  ): ("")
              ) : ('')}

              {activeIndex === 1 ? (//Если пользователь выбрал ввод динамики
                <>
                  <div className="tcontrol__dynamic" id="dynamic">
                    {/* <div className="tcontrol__title-box tcontrol__title-box--dynamic">
                        <h3 className="tcontrol__title">Динамические параметры</h3>
                        <span></span>
                      </div> */}
                    <div className="btn-group tcontrol__main-btn__group" role="group">
                      <button type="button" className={`btn btn-primary ${addButtons.dynamic === 1 ? "active" : ""}`} onClick={() => setAddButtons({ ...addButtons, dynamic: 1 })}>Работа оборудования</button>
                      <button type="button" className={`btn btn-primary ${addButtons.dynamic === 2 ? "active" : ""}`} onClick={() => setAddButtons({ ...addButtons, dynamic: 2 })}>Энергопотребление</button>
                      <button type="button" className={`btn btn-primary ${addButtons.dynamic === 3 ? "active" : ""}`} onClick={() => setAddButtons({ ...addButtons, dynamic: 3 })}>Оперативные параметры</button>
                    </div>

                    {addButtons.dynamic === 1 ? (//Работа оборуования
                      <div className="container">
                        <table className="table table-striped table-bordered  tcontrol__table">
                          <thead>
                            <tr>
                              <th colSpan="7">
                                <Timer />
                              </th>
                            </tr>
                            <tr>
                              <th rowSpan="2">Котельные</th>
                              <th colSpan="3">Котлы</th>
                              <th colSpan="3">Насосы</th>
                            </tr>
                            <tr>
                              <th>В работе</th>
                              <th>В резерве</th>
                              <th>Ремонт</th>
                              <th>В работе</th>
                              <th>В резерве</th>
                              <th>Ремонт</th>
                            </tr>
                          </thead>
                          <tbody>
                            {boilersHouses.map(item => {
                              let matchingBoilers = boilersHouseBoiler.filter(item1 => item.pk === item1.boilerhouse_id)
                              let matchingPumps = boilersHousePump.filter(item1 => item.pk === item1.boilerhouse_id)
                              return (
                                <tr>
                                  <td>{item.address}</td>
                                  <td>
                                    {matchingBoilers
                                      .filter(item1 => item1.status === "В работе")
                                      .map(item1 => item1.number)
                                      .join(", ")}
                                  </td>
                                  <td>
                                    {matchingBoilers
                                      .filter(item1 => item1.status === "В резерве")
                                      .map(item1 => item1.number)
                                      .join(", ")}
                                  </td>
                                  <td>
                                    {matchingBoilers
                                      .filter(item1 => item1.status === "В ремонте")
                                      .map(item1 => item1.number)
                                      .join(", ")}
                                  </td>
                                  <td>
                                    {matchingPumps
                                      .filter(item1 => item1.status === "В работе")
                                      .map(item1 => item1.number)
                                      .join(", ")}
                                  </td>
                                  <td>
                                    {matchingPumps
                                      .filter(item1 => item1.status === "В резерве")
                                      .map(item1 => item1.number)
                                      .join(", ")}
                                  </td>
                                  <td>
                                    {matchingPumps
                                      .filter(item1 => item1.status === "В ремонте")
                                      .map(item1 => item1.number)
                                      .join(", ")}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    ) : ("")}

                    {addButtons.dynamic === 2 ? (//Энергопотребление (большая сводная)
                      <div className="container">
                        <h2 className="text-warning">В данный момент в разработке...</h2>
                        {/* {printOperationTable()} */}
                        {/* <table className="table table-striped table-bordered  tcontrol__table">

                          <thead>
                            <tr>
                              <th>Час</th>
                              {dynamicValuesGet.map(item => {
                                let matchingboilersHouses = boilersHouses.filter(item1 => item.boilerhouse_id === item1.pk)
                                return (
                                  <>
                                    <th>
                                      {matchingboilersHouses.map(item1 => item1.address)}
                                    </th>
                                  </>
                                )
                              })}
                            </tr>

                          </thead>
                          <tr>
                                  <th>
                                    {formatDate(item.created_at).split(':')[0]}
                                  </th>
                                </tr>


                        </table> */}
                      </div>
                    ) : ("")}

                    {addButtons.dynamic === 3 ? (//Оперативные данные
                      <div className="container">
                        {dynamicValuesGet.length !== 0 && dynamicValuesCheckedBoiler !== -1 ? (
                          <table className="table table-striped table-bordered  tcontrol__table">
                            <thead>
                              <tr>
                                <th colSpan='14'>Параметры контроля</th>
                              </tr>
                              <tr>
                                <th colSpan='4'></th>
                                <th colSpan='1'>Давление воды на вводе</th>
                                <th colSpan='4'>Теплоноситель</th>
                                <th colSpan='4'>ГВС</th>
                                <th colSpan='1'>Давление газа на вводе</th>
                              </tr>
                              <tr>
                                <th>Время/Дата</th>
                                <th>Котельная</th>
                                <th>ФИО оператора</th>
                                <th>ФИО передающего</th>
                                <th>Pвх</th>
                                <th>T1</th>
                                <th>P1</th>
                                <th>T2</th>
                                <th>P2</th>
                                <th>T3</th>
                                <th>P3</th>
                                <th>T4</th>
                                <th>P4</th>
                                <th>Pгаз</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dynamicValuesGet.map(item => {
                                const matchingBoilerhouse = boilersHouses.find(item1 => item.boilerhouse_id === item1.pk && item1.pk === dynamicValuesCheckedBoiler);

                                if (matchingBoilerhouse) {
                                  return (
                                    <tr key={item.id}>
                                      <td>{formatDate(item.created_at)}</td>
                                      <td>{matchingBoilerhouse.address}</td>
                                      <td>{activeSmena.fullname}</td>
                                      <td>пока пусто</td>
                                      <td>{item.pressure}</td>
                                      <td>{item.t1}</td>
                                      <td>{item.p1}</td>
                                      <td>{item.t2}</td>
                                      <td>{item.p2}</td>
                                      <td>{item.t3}</td>
                                      <td>{item.p3}</td>
                                      <td>{item.t4}</td>
                                      <td>{item.p4}</td>
                                      <td>{item.gas}</td>
                                    </tr>
                                  );
                                }
                                return null; // Если нет соответствия, возвращаем null или пустой фрагмент
                              })}
                            </tbody>
                          </table>
                        ) : ("")}

                        <form className="tcontrol__form tcontrol__form--dynamic" onSubmit={e => (handleSubmit(postData('dynamicValues', '/TControl/dynamic_settings/', {
                          ...dynamicValuesPost, smena_id: activeSmena.pk,
                          boilerhouse_id: dynamicValuesCheckedBoiler
                        }, undefined, undefined, e)))} >
                          <div className="tcontrol__form-row">
                            {printDynamicInput()}
                          </div>
                          {coolantActive !== 0 && dynamicValuesCheckedBoiler !== -1 ? (
                            <div className="tcontrol__dynamicValues-btn">
                              <button className="btn btn-primary tcontrol__btn-input" type="submit">Сохранить</button>
                              {/* <span>C последнего ввода: <b>{timeLastInput}</b></span> */}
                            </div>
                          ) : ("")}
                        </form>
                      </div>
                    ) : ("")}

                  </div>
                </>
              ) : ("")}

              {activeIndex === 5 ? (//Закрытие смены
                <div className="tcontrol__closeSmena" id="closeSmena">
                  <div className="tcontrol__title-box tcontrol__title-box--closeSmena">
                    <h3 className="tcontrol__title">Закрытие смены</h3>
                    <span></span>
                  </div>
                  <div className="container">
                    <div className="tcontrol__btn-box">
                      <button className="tcontrol__btn tcontrol__register-btn" onClick={e => (
                        parseInt(localStorage.getItem('smena_id')) === activeSmena.pk ? (
                          patchData('smena', `/TControl/smena/${activeSmena.pk}/`, { flag_complete: true }, e)
                          ,
                          setCircle(true)
                        ) : ({})
                      )}>Закрыть смену</button>
                    </div>
                  </div>
                </div>
              ) : ("")}
            </div>
          </>
        ) : (<Jelly />)
      )}




    </>
  )
}
export default TControl