//Компонента добавления износа элементов
import React, { useState } from "react";
import ButtonFundament from "./ButtonAddWear/ButtonFundament";
import ButtonBalcon from "./ButtonAddWear/ButtonBalcon";
import ButtonAddWear from "./ButtonAddWear/ButtonAddWear";
import ButtonRoof from "./ButtonAddWear/ButtonRoof";
import ButtonWall from "./ButtonAddWear/ButtonWall";
import ButtonKrovla from "./ButtonAddWear/ButtonKrovla";
import ButtonPerecritia from "./ButtonAddWear/ButtonPerecritia";
import ButtonInz from "./ButtonAddWear/ButtonInz";
import ButtonElementWear from "./ButtonAddWear/ButtonElementWear";
import ButtonCloseElementWear from "./ButtonAddWear/ButtonCloseElementWear";
import ButtonFullWear from "./ButtonAddWear/ButtonFullWear";
import ButtonAddClearNextPrefWear from "./ButtonAddWear/ButtonAddClearNextPrefWear";
import ExportExcel from "./ButtonAddWear/ExportExcel";

import "../css/Wear.css";

const Wear = ({ updateState }) => {
  //Выбранный компонент(фундамент, крыша..)
  const [selectedComponent, setSelectedComponent] = useState(null);
  //Данные выводимые в вспомогательной таблице
  const [tableData, setTableData] = useState([]);
  //Состояние отображения элемента
  const [isFieldsVisible, setIsFieldsVisible] = useState(false);
  //Состояние вводимых данных в износ мкд
  const [inputData, setInputData] = useState({
    field1: "",
    field2: "",
    field3: "",
  });

  const handleButtonClick = (componentName, data) => {
    if (selectedComponent === componentName) {
      // Если кнопка была повторно нажата, скрываем поля и таблицу
      setSelectedComponent(null);
      setTableData([]);
      setIsFieldsVisible(false);
    } else {
      // Если кнопка была нажата в первый раз, отображаем поля и таблицу
      setSelectedComponent(componentName);
      setTableData(data);
      setIsFieldsVisible(true);
    }
  };

  const handleInputChange = (e) => {
    // Обновление состояния ввода при изменении полей ввода
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });
  };

  const StartStateFunc = () => {
    updateState(0)
  }

  //Отображение конкретного элемента
  const renderFieldsAndTable = () => {
    if (isFieldsVisible && selectedComponent) {
      switch (selectedComponent) {
        case "Износ по элементам":
          return (
            <div className="wear-container-table-btn">
              <table className="wear-table">
                <tbody>
                  {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex} className="wear-row">
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className="wear-cell">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="wear-buttons">
                <ButtonCloseElementWear
                  onButtonClick={handleButtonClick}
                  selectedComponent={selectedComponent}
                />
              </div>
            </div>
          );

        case "Полный износ здания":
          return (
            <div className="wear-container-table-btn">
              <table className="wear-table">
                <tbody>
                  {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex} className="wear-row">
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className="wear-cell">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="wear-btn-wear">
                <ButtonCloseElementWear
                  onButtonClick={handleButtonClick}
                  selectedComponent={selectedComponent}
                />
              </div>
            </div>
          );

        case "Фундамент":
          return (
            <div className="wear-container-table-btn">
              <div className="wear-input-container-addwear">
                <input
                  type="text"
                  name="field1"
                  placeholder="Обозначение участка"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="field2"
                  placeholder="Фактический физический износ участка элементов:"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="field3"
                  placeholder="Площадь участка"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
              </div>

              <table className="wear-table">
                <tbody>
                  {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex} className="wear-row">
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className="wear-cell">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="wear-buttons">
                <ButtonAddWear
                  onButtonClick={handleButtonClick}
                  inputData={inputData}
                  selectedComponent={selectedComponent}
                  setInputData={setInputData}
                />
              </div>
            </div>
          );

        case "Стены":
          return (
            <div className="wear-container-table-btn">
              <div className="wear-input-container-addwear">
                <input
                  type="text"
                  name="field1"
                  placeholder="Обозначение участка"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="field2"
                  placeholder="Фактический физический износ участка элементов:"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="field3"
                  placeholder="Площадь участка"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
              </div>

              <table className="wear-table">
                <tbody>
                  {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex} className="wear-row">
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className="wear-cell">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="wear-buttons">
                <ButtonAddWear
                  onButtonClick={handleButtonClick}
                  inputData={inputData}
                  selectedComponent={selectedComponent}
                  setInputData={setInputData}
                />
              </div>
            </div>
          );

        case "Крыша":
          return (
            <div className="wear-container-table-btn">
              <div className="wear-input-container-addwear">
                <input
                  type="text"
                  name="field1"
                  placeholder="Обозначение участка"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="field2"
                  placeholder="Фактический физический износ участка элементов:"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="field3"
                  placeholder="Площадь участка"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
              </div>

              <table className="wear-table">
                <tbody>
                  {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex} className="wear-row">
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className="wear-cell">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="wear-buttons">
                <ButtonAddWear
                  onButtonClick={handleButtonClick}
                  inputData={inputData}
                  selectedComponent={selectedComponent}
                  setInputData={setInputData}
                />
              </div>
            </div>
          );

        case "Перекрытия":
          return (
            <div className="wear-container-table-btn">
              <div className="wear-input-container-addwear">
                <input
                  type="text"
                  name="field1"
                  placeholder="Обозначение участка"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="field2"
                  placeholder="Фактический физический износ участка элементов:"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="field3"
                  placeholder="Площадь участка"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
              </div>

              <table className="wear-table">
                <tbody>
                  {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex} className="wear-row">
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className="wear-cell">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="wear-buttons">
                <ButtonAddWear
                  onButtonClick={handleButtonClick}
                  inputData={inputData}
                  selectedComponent={selectedComponent}
                  setInputData={setInputData}
                />
              </div>
            </div>
          );

        case "Кровля":
          return (
            <div className="wear-container-table-btn">
              <div className="wear-input-container-addwear">
                <input
                  type="text"
                  name="field1"
                  placeholder="Обозначение участка"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="field2"
                  placeholder="Фактический физический износ участка элементов:"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="field3"
                  placeholder="Площадь участка"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
              </div>

              <table className="wear-table">
                <tbody>
                  {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex} className="wear-row">
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className="wear-cell">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="wear-buttons">
                <ButtonAddWear
                  onButtonClick={handleButtonClick}
                  inputData={inputData}
                  selectedComponent={selectedComponent}
                  setInputData={setInputData}
                />
              </div>
            </div>
          );

        case "Балкон":
          return (
            <div className="wear-container-table-btn">
              <div className="wear-input-container-addwear">
                <input
                  type="text"
                  name="field1"
                  placeholder="Обозначение участка"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="field2"
                  placeholder="Фактический физический износ участка элементов:"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="field3"
                  placeholder="Площадь участка"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
              </div>

              <table className="wear-table">
                <tbody>
                  {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex} className="wear-row">
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className="wear-cell">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="wear-buttons">
                <ButtonAddWear
                  onButtonClick={handleButtonClick}
                  inputData={inputData}
                  selectedComponent={selectedComponent}
                  setInputData={setInputData}
                />
              </div>
            </div>
          );

        case "Канализация":
          return (
            <div className="wear-container-table-btn">
              <div className="wear-input-container-addwear">
                <input
                  type="text"
                  name="field1"
                  placeholder="Обозначение участка"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="field2"
                  placeholder="Фактический физический износ участка элементов:"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="field3"
                  placeholder="Длина участка"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
              </div>

              <table className="wear-table">
                <tbody>
                  {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex} className="wear-row">
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className="wear-cell">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="wear-buttons">
                <ButtonAddClearNextPrefWear
                  onButtonClick={handleButtonClick}
                  inputData={inputData}
                  selectedComponent={selectedComponent}
                  setInputData={setInputData}
                />
              </div>
            </div>
          );

        case "Мусоропровод":
          return (
            <div className="wear-container-table-btn">
              <div className="wear-input-container-addwear">
                <input
                  type="text"
                  name="field1"
                  placeholder="Обозначение участка"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="field2"
                  placeholder="Фактический физический износ участка элементов:"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="field3"
                  placeholder="Длина участка"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
              </div>

              <table className="wear-table">
                <tbody>
                  {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex} className="wear-row">
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className="wear-cell">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="wear-buttons">
                <ButtonAddClearNextPrefWear
                  onButtonClick={handleButtonClick}
                  inputData={inputData}
                  selectedComponent={selectedComponent}
                  setInputData={setInputData}
                />
              </div>
            </div>
          );

        case "Центральное отопление":
          return (
            <div className="wear-container-table-btn">
              <div className="wear-input-container-addwear">
                <input
                  type="text"
                  name="field1"
                  placeholder="Обозначение участка"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="field2"
                  placeholder="Фактический физический износ участка элементов:"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="field3"
                  placeholder="Длина участка"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
              </div>

              <table className="wear-table">
                <tbody>
                  {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex} className="wear-row">
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className="wear-cell">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="wear-buttons">
                <ButtonAddClearNextPrefWear
                  onButtonClick={handleButtonClick}
                  inputData={inputData}
                  selectedComponent={selectedComponent}
                  setInputData={setInputData}
                />
              </div>
            </div>
          );

        case "Холодное водоснабжение":
          return (
            <div className="wear-container-table-btn">
              <div className="wear-input-container-addwear">
                <input
                  type="text"
                  name="field1"
                  placeholder="Обозначение участка"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="field2"
                  placeholder="Фактический физический износ участка элементов:"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="field3"
                  placeholder="Длина участка"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
              </div>

              <table className="wear-table">
                <tbody>
                  {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex} className="wear-row">
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className="wear-cell">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="wear-buttons">
                <ButtonAddClearNextPrefWear
                  onButtonClick={handleButtonClick}
                  inputData={inputData}
                  selectedComponent={selectedComponent}
                  setInputData={setInputData}
                />
              </div>
            </div>
          );

        case "Горячее водоснабжение":
          return (
            <div className="wear-container-table-btn">
              <div className="wear-input-container-addwear">
                <input
                  type="text"
                  name="field1"
                  placeholder="Обозначение участка"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="field2"
                  placeholder="Фактический физический износ участка элементов:"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="field3"
                  placeholder="Длина участка"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
              </div>

              <table className="wear-table">
                <tbody>
                  {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex} className="wear-row">
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className="wear-cell">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="wear-buttons">
                <ButtonAddClearNextPrefWear
                  onButtonClick={handleButtonClick}
                  inputData={inputData}
                  selectedComponent={selectedComponent}
                  setInputData={setInputData}
                />
              </div>
            </div>
          );

        case "Электрооборудование":
          return (
            <div className="wear-container-table-btn">
              <div className="wear-input-container-addwear">
                <input
                  type="text"
                  name="field1"
                  placeholder="Обозначение участка"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="field2"
                  placeholder="Фактический физический износ участка элементов:"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="field3"
                  placeholder="Длина участка"
                  className="wear-input-addwear"
                  onChange={handleInputChange}
                />
              </div>

              <table className="wear-table">
                <tbody>
                  {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex} className="wear-row">
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className="wear-cell">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="wear-buttons">
                <ButtonAddClearNextPrefWear
                  onButtonClick={handleButtonClick}
                  inputData={inputData}
                  selectedComponent={selectedComponent}
                  setInputData={setInputData}
                />
              </div>
            </div>
          );

        default:
          alert("Ошибка");
      }
    }
  };

  return (
    <div>
      <div className="conteiner_btn_addwear">
        <ButtonFundament
          onButtonClick={handleButtonClick}
          foundationType={localStorage.getItem("foundationType")}
        />
        <ButtonBalcon
          onButtonClick={handleButtonClick}
          balconyType={localStorage.getItem("balconyType")}
        />
        <ButtonRoof
          onButtonClick={handleButtonClick}
          roofType={localStorage.getItem("roofType")}
        />
        <ButtonWall
          onButtonClick={handleButtonClick}
          wallType={localStorage.getItem("wallType")}
        />
        <ButtonKrovla
          onButtonClick={handleButtonClick}
          roofingType={localStorage.getItem("roofingType")}
        />
        <ButtonPerecritia
          onButtonClick={handleButtonClick}
          floorType={localStorage.getItem("floorType")}
        />
        <ButtonInz onButtonClick={handleButtonClick} />
        <ButtonElementWear onButtonClick={handleButtonClick} />
        <ButtonFullWear onButtonClick={handleButtonClick} />

        <ExportExcel
          foundationType={localStorage.getItem("foundationType")}
          balconyType={localStorage.getItem("balconyType")}
          roofType={localStorage.getItem("roofType")}
          wallType={localStorage.getItem("wallType")}
          roofingType={localStorage.getItem("roofingType")}
          floorType={localStorage.getItem("floorType")}
          sewerageLength={localStorage.getItem("sewerageLength")}
          chuteLength={localStorage.getItem("chuteLength")}
          centralHeatingLength={localStorage.getItem("centralHeatingLength")}
          coldWaterSupplyLength={localStorage.getItem("coldWaterSupplyLength")}
          hotWaterSupplyLength={localStorage.getItem("hotWaterSupplyLength")}
          wiringLength={localStorage.getItem("wiringLength")}
        />

        <button className="btn_addwear_wear" onClick={StartStateFunc}>
          Назад
        </button>
      </div>

      {renderFieldsAndTable()}
    </div>
  );
};

export default Wear;
