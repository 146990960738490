import React, { useState, useEffect } from 'react';

const Timer = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Обновляем каждую секунду

    return () => clearInterval(interval);
  }, []);

  const formatTime = (value) => (value < 10 ? '0' + value : value);

  return (
    <div>
      <p>
        {formatTime(currentTime.getHours())}:
        {formatTime(currentTime.getMinutes())}:
        {formatTime(currentTime.getSeconds())}
      </p>
    </div>
  );
};

export default Timer;
