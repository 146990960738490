import React from "react";
import "../../css/Wear.css";

const ButtonKrovla = ({ onButtonClick, roofingType }) => {
  const handleClick = () => {
    let data;

    switch (roofingType) {
      case "1":
        data = [
          ["Признаки износа", "Физический износ, %", "Обозначение участка"],
          [
            "Одиночные мелкие повреждения и пробоины в кровле и местах примыкания к вертикальным поверхностям, прогиб настенных желобов",
            "0-20",
            "1",
          ],
          [
            "Вздутие поверхности, трещины, разрывы (местами верхнего слоя кровли, требующие замены до 10% кровли); ржавление и значительные повреждения настенных желобов и ограждающей решетки; проникновение влаги в местах примыканий к вертикальным поверхностям; повреждение деталей водоприемного устройства (в плоских крышах)",
            "21-40",
            "2",
          ],
          [
            "Разрушение верхнего и местами нижних слоев покрытия; вздутия, требующие замены от 10 до 25% кровельного покрытия; ржавление и разрушение настенных желобов или водоприемных устройств, свесов и компенсаторов; ротечки кровли местами; массовые повреждения ограждающей решетки",
            "41-60",
            "3",
          ],
          [
            "Массовые протечки, отслоения покрытия от основания, отсутствие частей покрытия, ограждающая решетка разрушена",
            "61-80",
            "4",
          ],
        ];

        break;
      case "2":
        data = [
          ["Признаки износа", "Физический износ, %", "Обозначение участка"],
          [
            "Одиночные мелкие повреждения и пробоины в кровельном покрытии, водоотводящие устройства и покрытия из оцинкованной стали погнуты, верхний защитный слой и защитно-отделочное покрытие кровли отсутствует на площади до 10%",
            "0-20",
            "1",
          ],
          [
            "Вздутия мастичного покрытия и повреждения (трещины, отслаивания в местах сопряжения с вертикальными конструкциями), требующие замены до 10% кровли; ржавление и значительные повреждения настенных желобов и ограждений решетки; повреждения деталей водоприемных устройств (в плоских крышах)",
            "21-40",
            "2",
          ],
          [
            "Разрывы мастичного покрытия, вздутия покрытия, требующие замены от 10 до 20% площади кровли; разрушение кровельного покрытия в местах примыкания к вертикальным поверхностям; протечки местами; значительное повреждение ограждающей решетки",
            "41-60",
            "3",
          ],
          [
            "Повреждения и просадки основания кровли, трещины в стыках панелей, массовые протечки, разрушение устройств примыкания и ограждающей решетки",
            "61-80",
            "4",
          ],
        ];

        break;
      case "3":
        data = [
          ["Признаки износа", "Физический износ, %", "Обозначение участка"],
          [
            "Ослабление крепления отдельных листов к обрешетке, отдельные протечки",
            "0-20",
            "1",
          ],
          [
            "Неплотности фальцев, пробоины и нарушение примыканий к выступающим частям местами; просветы при осмотре со стороны чердака; повреждения настенных желобов",
            "21-40",
            "2",
          ],
          [
            "Ржавчина на поверхности кровли, свищи, пробоины; искривление и нарушение креплений ограждающей решетки; большое количество протечек",
            "41-60",
            "3",
          ],
          [
            "Массовые протечки, сильная ржавчина на поверхности кровли со стороны чердака, разрушение фальцев, большое количество заплат на кровле, разрушение ограждающей решетки",
            "61-80",
            "4",
          ],
        ];

        break;
      case "4":
        data = [
          ["Признаки износа", "Физический износ, %", "Обозначение участка"],
          [
            "Искривление местами металлических желобов; ослабление креплений отдельных асбоцементных листов к обрешетке",
            "0-20",
            "1",
          ],
          [
            "Протечки и просветы в отдельных местах, отставание и трещины коньковых плит; отрыв листов до 10% площади кровли",
            "21-40",
            "2",
          ],
          [
            "Отсутствие отдельных листов, отколы и трещины, протечки, ослабление креплений листов к обрешетке",
            "41-60",
            "3",
          ],
          [
            "Массовое разрушение кровли, отсутствие части настенных желобов и обделки свесов, большое количество заплат из рулонных материалов",
            "61-80",
            "4",
          ],
        ];

        break;
      case "5":
        data = [
          ["Признаки износа", "Физический износ, %", "Обозначение участка"],
          [
            "Единичные щели и неплотное примыкание черепиц, частичное нарушение промазки между черепицами",
            "0-20",
            "1",
          ],
          [
            "Повреждения отдельных черепиц (не более 1 черепицы на 1 м2); пробоины и ржавчина в подвесных желобах. Массовые разрушения промазки швов",
            "21-40",
            "2",
          ],
          [
            "Повреждение и раскол отдельных черепиц (2-3 черепицы на 1 м2), протечки, просветы, проникание воды и снега через щели",
            "41-60",
            "3",
          ],
          [
            "Массовые протечки кровли, отставание и повреждение большинства черепиц, большое количество заплат, отсутствие части обделок и подвесных желобов",
            "61-80",
            "4",
          ],
        ];

        break;
      case "6":
        data = [
          ["Признаки износа", "Физический износ, %", "Обозначение участка"],
          [
            "Загрязненность кровли, повреждение отдельных дранок до 5% площади кровли",
            "0-20",
            "1",
          ],
          [
            "Выпадение отдельных дранок до 10% площади кровли, ржавчина на металлических обрамлениях",
            "21-40",
            "2",
          ],
          [
            "Гниль или выпадение дранок на площади до 40% кровли, лишайники на поверхности кровли",
            "41-60",
            "3",
          ],
          ["Массовое поражение гнилью и выпадение дранок", "61-80", "4"],
        ];

        break;
      case "7":
        data = [
          ["Признаки износа", "Физический износ, %", "Обозначение участка"],
          ["Мелкие повреждения досок, загрязненность кровли", "0-20", "1"],
          [
            "Трещины в досках верхнего и нижнего ряда, наличие ржавчины в металлических желобах, протечки в отдельных местах",
            "21-40",
            "2",
          ],
          [
            "Поражение гнилью досок верхнего слоя, трещины в досках, массовые протечки кровли",
            "41-60",
            "3",
          ],
          [
            "Массовые поражения гнилью и жучком досок, отпадение досок верхнего и нижнего слоев, разрушение подвесных желобов",
            "61-80",
            "4",
          ],
        ];

        break;

      default:
        console.log("Ошибка");
    }

    onButtonClick("Кровля", data);
  };

  return (
    <button className="btn_addwear_wear" onClick={handleClick}>
      Кровля
    </button>
  );
};

export default ButtonKrovla;
