import React from "react";
import "../../css/Wear.css";
import { API_URL } from "../../../../config";
import { useContext } from "react";
import AuthContext from "../../../../context/AuthContext";

const ButtonFullWear = ({ onButtonClick }) => {
  let { authTokens } = useContext(AuthContext);

  const id = localStorage.getItem("id");

  // const accessToken = localStorage.getItem("accessToken");

  const fetchData = async () => {
    const api = `${API_URL}/Wear/Damage/calculateTotalWear/${id}/`;

    try {
      const response = await fetch(api, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      });

      if (!response.ok) {
        throw new Error("Ошибка при получении данных");
      }

      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Ошибка !", error.message);
      return null;
    }
  };

  const handleAddClick = async () => {
    const results = {
      fullwear: await fetchData(),
    };

    let tableData;

    // Проверяем, есть ли значение в results.fullwear
    let fullwearValue = results.fullwear !== null ? results.fullwear.toFixed(2) + "%" : "0%";
    
    // Добавляем в таблицу
    tableData = [["Полный износ здания"], [fullwearValue]];
    
    // Пример вывода данных
    console.log(tableData);
    

    console.log(results);

    onButtonClick("Полный износ здания", tableData);
  };

  return (
    <button className="btn_addwear_wear" onClick={handleAddClick}>
      {" "}
      Износ здания{" "}
    </button>
  );
};

export default ButtonFullWear;
